import React from "react"
import Case from '../../../sections/Case';
import {useEnCaseData} from '../../../shared/Hooks/Case/useEnCaseData';
import {useEnHeaderData} from '../../../shared/Hooks/Headers/useEnHeaderData';

const CaseCersanit = () => {
  const data = {
    seo: {
      title: 'Cersanit i Opoczno - projekt shop in shop.',
    },
    cases: useEnCaseData().cersanit,
    header: useEnHeaderData().cersanit,
  };

  return (
    <Case data={data}/>
  );
}

export default CaseCersanit
